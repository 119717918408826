<template>
  <div class="bg-white">
    <TsRow append-class="m-0 p-4">
      <TsColumn append-class="p-0 is-0 ms-1">
        <TsIcon name="humbleicons:cart" size="27" />
      </TsColumn>
      <TsColumn append-class="p-0 is-11 lg:flex lg:gap-2 lg:items-center">
        <TsTypography append-class="mb-0" as="h4">
          Items added to trolley
        </TsTypography>
        <TsTag
          v-if="tagLabel.length"
          :label="tagLabel"
          :variant="titleTagVariant"
          data-testid="trolley-multiple-items-confirmation-modal-heading-channel-tag"
          rounded
          outlined
          append-class="outline-0 ms-2 font-semibold"
          size="sm"
        />
      </TsColumn>
      <TsColumn append-class="p-0 is-1">
        <TsIcon
          name="bitcoin-icons:cross-filled"
          @click="emit('close')"
          data-testid="trolley-multiple-items-confirmation-modal-close-icon"
          class="absolute top-3 right-3 text-idle-black hover:bg-info/10 rounded transition-colors cursor-pointer"
        />
      </TsColumn>
    </TsRow>

    <TsDivider append-class="w-full p-0 m-0" />

    <div v-if="props.products.length" class="max-h-[70vh] overflow-y-auto">
      <TsMedia
        v-for="product in products"
        :key="product.code"
        append-class="border-b border-natural-light-grey"
      >
        <TsMediaStart append-class="mt-4 mx-2 pl-2 bg-white rounded-xl pr-2 relative">
          <TsIcon
              name="material-symbols:check-circle-rounded"
              class="text-success absolute top-0 left-0"
              size="24"
          />
          <NuxtImg
            class="rounded-lg"
            :src="product.image"
            :alt="`trolley-item-confirmation-${product.name}-image`"
            :data-testid="`trolley-item-confirmation-${product.name}-image`"
            width="65"
            height="65"
          />
        </TsMediaStart>
        <TsMediaContent append-class="py-4">
          <TsRow append-class="m-0 pr-7">
            <TsTypography
              v-if="product.full_name"
              size="sm"
              append-class="mb-0 text-idle-black"
              :data-testid="`trolley-item-confirmation-${product.name}-full-name`"
            >
              <span class="font-bold">{{
                product?.full_name.split(" ")[0]
              }}</span>
              {{ product?.full_name?.split(" ").slice(1).join(" ") }}
            </TsTypography>
          </TsRow>
          <TsRow append-class="m-0 py-1 gap-5">
            <TsTypography
              size="sm"
              append-class="mb-0 text-natural-grey"
              :data-testid="`trolley-item-confirmation-${product.name}-quantity`"
            >
              Quantity &nbsp; {{ product?.quantity }}
            </TsTypography>
            <TsTypography
              size="sm"
              append-class="mb-0 text-natural-grey"
              :data-testid="`trolley-item-confirmation-${product.name}-net-price`"
            >
              {{ product?.prices?.formatted.net }}
            </TsTypography>
          </TsRow>
          <TsRow v-if="!tagLabel.length">
            <TsTag
              :label="TrolleyChannel[product.channel]"
              :data-testid="`trolley-item-confirmation-${product.name}-individual-channel-tag`"
              variant="info"
              rounded
              size="sm"
            />
          </TsRow>
        </TsMediaContent>
      </TsMedia>

      <div class="bg-natural-soft-white">
        <TsColumn append-class="pb-1">
          <TsTypography
            weight="semibold"
            append-class="text-idle-black pl-3 leading-5"
          >
          {{ useTranslation("suggestion", "You may also like") }}
          </TsTypography>
        </TsColumn>

        <TsRow
          append-class="m-0 px-3 pb-1 flex overflow-x-auto whitespace-nowrap no-scrollbar"
        >
        <TsModalProductCard v-for="product in showproducts" :key="product.code" :product="product"
        :addToTrolleyLabel="useTranslation('addToTrolley', 'Add to Trolley')" />
        </TsRow>
        
      </div>
    </div>

    <div
      class="p-5"
      v-else
      data-testid="trolley-multiple-items-confirmation-modal-initial-loader"
    >
      <TsBranchTransition v-for="n in 3" :key="n" />
    </div>

    <TsRow append-class="m-0 justify-between p-2">
      <TsColumn>
        <TsButton
          block
          :label="continueShoppingCTALabel"
          data-testid="trolley-multiple-items-confirmation-modal-continue-shopping-cta"
          size="sm"
          outlined
          @click="emit('close')"
        />
      </TsColumn>
      <TsColumn>
        <TsButton
          block
          icon="mdi:trolley-outline"
          :label="goToTrolleyCTALabel"
          data-testid="trolley-multiple-items-confirmation-modal-go-to-trolley-cta"
          @click="emit('goToTrolley')"
          size="sm"
        />
      </TsColumn>
    </TsRow>
  </div>
</template>

<script lang="ts" setup>
import { ProductService } from "~/services/product.service";
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";

const monetateStore = useMonetateStore();
const showproducts = ref([])

onMounted(async () => {
  if (!monetateStore.trolley_you_may_also_like_ids) return;
  const data_ids = monetateStore.trolley_you_may_also_like_ids.map(item => item)
  if(data_ids.length === 0){
    return false;
  }
  showproducts.value = await new ProductService().getProducts(data_ids);
})


type Props = {
  products: TrolleyItemVariant[];
  branch?: string;
};

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  close: any;
  goToTrolley: any;
}>();

const tagLabel = computed(() => {
  const allChannels = props.products.map((product) => product.channel);

  if (allChannels.every((channel) => channel === TrolleyChannel.Collection)) {
    return `Collection at ${props.branch ?? "my branch"}`;
  }

  if (
    allChannels.every((channel) => channel === TrolleyChannel.NextDayCollection)
  ) {
    return `Next Day Collection at ${props.branch ?? "my branch"}`;
  }

  if (allChannels.every((channel) => channel === TrolleyChannel.Delivery)) {
    return "Delivery";
  }

  if (allChannels.every((channel) => channel === TrolleyChannel.Directship)) {
    return "Directship";
  }

  return "";
});

const titleTagVariant = computed(() =>
  tagLabel.value === "Delivery" || tagLabel.value === "Directship"
    ? "success"
    : "info"
);

const continueShoppingCTALabel = useTranslation(
  "continueShopping",
  "Continue shopping"
);
const goToTrolleyCTALabel = useTranslation("goToTrolley", "Go to trolley");
</script>
